.container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

h2 {
    margin-bottom: 20px;
}

form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:disabled {
    background-color: #ccc;
}

.error {
    color: red;
    margin-top: 10px;
}

.disabled-input {
    background-color: #f5f5f5;
    color: #a9a9a9;
    cursor: not-allowed;
    border: 1px solid #ccc;
}