/* General Reset */
body, h1, h2, h3, p {
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
    background-color: #E8F1F5; /* Cool White */
    color: #2E3B4E; /* Slate Gray */
}

/* Hero Section */
.hero {
    position: relative;
    height: 500px;
    overflow: hidden;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
}

.hero-background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.hero-content {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    z-index: 2;
    padding: 20px 30px;
    color: #2E3B4E;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 8px;
    max-width: 40%;
    text-shadow: none;
}

.hero-title {
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.hero-subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.5;
}

.cta-btn {
    display: inline-block;
    padding: 15px 30px;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: #007BFF;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    transition: background-color 0.3s ease, transform 0.2s ease;
    cursor: pointer;
}

.cta-btn:hover {
    background-color: #FF5E5B; /* Bright Coral */
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* Features Section */
.features {
    display: flex;
    justify-content: space-around;
    padding: 50px 30px;
    background-color: #FFFFFF;
}

.feature {
    max-width: 30%;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #E8F1F5;
}

.feature h3 {
    color: #007BFF; /* Electric Blue */
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.feature p {
    color: #555;
    line-height: 1.4;
}

/* Testimonials Section */
.testimonials {
    padding: 50px 30px;
    text-align: center;
    background-color: #FFFFFF;
}

.section-title {
    font-size: 2rem;
    color: #2E3B4E;
    margin-bottom: 30px;
}

.testimonial {
    margin: 20px auto;
    max-width: 600px;
    font-style: italic;
    color: #555;
}

.testimonial span {
    display: block;
    margin-top: 10px;
    font-weight: bold;
    color: #007BFF;
}