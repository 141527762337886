/* General Container */
.profile-display-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 30px auto;
    max-width: 1200px;
    padding: 20px;
}

/* Profile Header */
.profile-header {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.profile-picture {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-info h2 {
    font-size: 1.8rem;
    margin: 0;
    color: #2E3B4E;
}

.profile-info h3 {
    font-size: 1.2rem;
    margin: 5px 0;
    color: #555;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.tag-bubble {
    background-color: #E8F1F5; /* Cool White */
    color: #2E3B4E; /* Slate Gray */
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 0.9rem;
    text-transform: uppercase; /* Makes text all caps */
    letter-spacing: 1.5px; /* Adds spacing between letters */
    font-weight: bold; /* Optional: Makes text bold for prominence */
}

/* Cards Section */
.card-container {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card {
    flex: 1 1 calc(33.33% - 20px); /* Three cards per row */
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.card-heading {
    font-size: 1.2rem;
    color: #007BFF;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.card-heading-2 {
    font-size: 1.2rem;
    color: #007BFF;
    background-color: #f4f4f9;
    padding: 10px;
    margin-bottom: 10px;
    border-left: 5px solid #05e960; /* Decorative border */
    text-transform: uppercase;
}

.card-heading-3 {
    font-size: 1.2rem;
    color: #007BFF;
    background-color: #f4f4f9;
    padding: 10px;
    margin-bottom: 10px;
    border-left: 5px solid #ea0c0c; /* Decorative border */
    text-transform: uppercase;
}

.card p {
    font-size: 0.9rem;
    color: #555;
}

/* Loading Overlay */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
}

.loading-spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}