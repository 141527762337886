.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #E8F1F5; /* Cool White */
}