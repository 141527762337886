.page-container {
    max-width: 800px;
    margin: 30px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-container {
    text-align: center;
}

.form-title {
    font-size: 2rem;
    color: #2E3B4E; /* Slate Gray */
    margin-bottom: 10px;
}

.form-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

.input-group input {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.button-group {
    text-align: center;
}

.submit-btn {
    padding: 12px 30px;
    font-size: 1rem;
    font-weight: bold;
    background-color: #007BFF; /* Electric Blue */
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-btn:hover {
    background-color: #0056b3; /* Darker Electric Blue */
    transform: scale(1.05);
}

.feedback-message {
    color: red;
    margin-top: 10px;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-direction: column;
    font-size: 1.5em;
    color: #333;
}

.loading-spinner {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #007BFF;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}