footer {
    text-align: center;
    padding: 20px;
    font-size: 0.9em;
    color: #555; /* Muted gray */
    background-color: #FFFFFF;
    margin-top: 40px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

footer p {
    margin: 5px 0;
    font-size: 1rem;
    color: #2E3B4E; /* Slate Gray */
}