/* Header Container */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Vertically centers everything */
    padding: 15px 30px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
}

/* Brand Section: Logo and Cognix Text */
.brand {
    display: flex;
    align-items: center;
    text-decoration: none; /* Remove underline */
}

.brand-link {
    display: flex;
    align-items: center;
    text-decoration: none; /* Ensures no underline */
    color: inherit; /* Uses the parent color */
}

.logo-image {
    width: 60px; /* Adjust logo size */
    height: auto;
    margin-right: 10px; /* Space between logo and text */
}

.logo {
    font-size: 2rem;
    font-weight: bold;
    color: #FF5E5B; /* Bright Coral */
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
}

/* Navigation Menu */
/* Navigation Menu */
.nav {
    display: flex;
    align-items: center;
    gap: 30px; /* Increased spacing between menu items */
    flex-wrap: nowrap; /* Prevent wrapping */
}

/* Individual Menu Links */
.nav-link,
.dropdown-btn {
    font-size: 1rem;
    font-weight: bold;
    color: #2E3B4E; /* Slate Gray */
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap; /* Prevent text wrapping */
    transition: color 0.3s ease;
    padding: 5px 10px;
}

.nav-link:hover,
.dropdown-btn:hover {
    color: #007BFF; /* Electric Blue */
    cursor: pointer;
}

/* Dropdown */
.dropdown {
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    min-width: 150px;
    padding: 10px 0;
    z-index: 100;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-link {
    display: block;
    padding: 10px 15px;
    color: #2E3B4E;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-link:hover {
    background-color: #F0F4F8; /* Light gray background on hover */
    color: #007BFF;
}

/* User Info and Buttons */
.user-info {
    font-size: 0.9rem;
    color: #2E3B4E;
    margin-right: 15px;
}

.login-btn,
.logout-btn {
    padding: 8px 16px;
    font-size: 1rem;
    font-weight: bold;
    background-color: #007BFF;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.login-btn:hover,
.logout-btn:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}