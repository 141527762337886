body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.container {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 100%;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

input, button {
  margin: 10px 0;
  padding: 10px;
  font-size: 1em;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  border-radius: 5px;
}

input {
  border: 1px solid #ccc;
}

button {
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

.profile-summary, .profile-section {
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-summary h2, .profile-section h3 {
  margin: 0 0 10px;
}

.profile-picture {
  float: left;
  margin-right: 20px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 0;
}

.bar {
  height: 25px;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
  color: white;
  text-align: right;
  padding-right: 10px;
  line-height: 25px;
}

.bar-dominance {
  background-color: #ff5733;
}

.bar-influence {
  background-color: #FFD700; /* Yellow */
}

.bar-steadiness {
  background-color: #33ff57;
}

.bar-compliance {
  background-color: #1E90FF; /* Blue */
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

#loading-messages {
  text-align: center;
  margin-top: 10px;
  font-size: 1.2em;
  color: #555;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* .profile-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
  margin: auto;
} */

.card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.bar-chart {
  margin-top: 20px;
}

.bar-label {
  font-size: 0.9em;
  margin-bottom: 5px;
}

/* Tag bubbles */
.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.tag-bubble {
  background-color: #e0e0e0;
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 0.9em;
  color: #333;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

h3 {
  margin-top: 0;
}

p {
  margin: 5px 0;
}

/* Trait line */
.trait-line-container {
  margin: 15px 0;
  display: flex;
  align-items: center;
}

.trait-line-wrapper {
  flex-grow: 1;
  position: relative;
  margin: 0 10px; /* Space between the line and the labels */
}

.trait-line {
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  position: relative;
  width: 100%; /* Ensures the line takes up the full available space */
}

.trait-dot {
  position: absolute;
  top: -8px;
  width: 25px;
  height: 25px;
  background-color: #FF5733;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  transform: translateX(-50%);
}

.initials {
  font-size: 0.8em;
}

.trait-end-label {
  font-size: 0.9em;
  color: #555;
  width: 100px; /* Fixed width to ensure alignment */
  text-align: center;
  white-space: nowrap;
}

/* Fullscreen loading overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  flex-direction: column;
  font-size: 1.5em;
  color: #333;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin-bottom: 20px;
}

.loading-text {
  font-size: 1.2em;
  color: #555;
  text-align: center;
}

/* Card Heading Styling */
.card-heading {
  font-size: 1.4em;
  color: #333;
  background-color: #f4f4f9;
  padding: 10px;
  border-left: 5px solid #3498db; /* Decorative border */
  margin-bottom: 20px;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* General Button Styling */
button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #007BFF; /* Electric Blue */
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #0056b3; /* Darker Electric Blue */
  transform: scale(1.05); /* Slight scale effect on hover */
}

/* Header and Footer Styles */
header, footer {
  width: 100%; /* Full width for the header and footer */
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
